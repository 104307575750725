import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import Arrow from '@icons/menu/Arrow.svg';

import styles from './NavButton.module.sass';

// Props
export type NavButtonProps = {
  path?: string;
  href?: string;
  label: string;
  isLast?: boolean;
  isHidden?: boolean;
  icon?: React.ReactNode;
  iconActive?: React.ReactNode;
  onClick?: () => void;
};

const NavButton: React.FC<NavButtonProps> = ({
  path,
  label,
  icon,
  iconActive,
  isLast = false,
  onClick,
  href,
}) => {
  const router = useRouter();
  const baseAsPath = router.pathname.replace('[shortcut]', router.query.shortcut as string);

  const content = (
    <div
      className={cx(
        styles.NavButton,
        { [styles.active]: path && baseAsPath === path },
        { [styles.Last]: isLast },
      )}
      onClick={onClick}
    >
      <div className={styles.Content}>
        <div className={styles.Icon}>
          {icon}
          <div className={styles.IconActive}>{iconActive}</div>
        </div>
        <span className={styles.Label}>{label}</span>
      </div>
      <div className={styles.Arrow}>
        <Arrow />
      </div>
    </div>
  );

  if (path) {
    return (
      <Link prefetch={false} href={href || '/marketplace/[shortcut]'} as={path}>
        {content}
      </Link>
    );
  }
  return content;
};

export default NavButton;
