import React, { ReactNode } from 'react';

import Marketplace from '@icons/menu/Marketplace.svg';
import MarketplaceActive from '@icons/menu/MarketplaceActive.svg';
import MarketplaceActiveDarkTheme from '@icons/menu/MarketplaceActiveDarkTheme.svg';

export const navButtonAll: (isLightTheme: boolean) => {
  label: string;
  path: string;
  href?: string;
  icon: ReactNode;
  isHidden?: boolean;
  iconActive: ReactNode;
  onClick?: () => void;
}[] = (isLightTheme: boolean) => [
  {
    label: 'All',
    href: '/marketplace',
    path: '/marketplace',
    icon: <Marketplace />,
    iconActive: isLightTheme ? <MarketplaceActive /> : <MarketplaceActiveDarkTheme />,
  },
];
