import queryString from 'querystring';

import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import LanguageChange from '@components/layout/LanguageChange/LanguageChange';
import UserMenu from '@components/layout/UserMenu/UserMenu';
import ArrowIcon from '@icons/bread-crumbs-arrow.svg';
import FaqIcon from '@icons/ui-kit/faq.svg';
import PackageIcon from '@icons/ui-kit/package.svg';
import { useFullProfile } from '@src/api/Api';
import { useCurrentTheme, THEME } from '@src/api/local/theme';
import { UserRole } from '@src/api/models/enums';
import { Media } from '@src/utils/media';
import Button from 'src/ui-kit/button/Button';
import CustomLink from 'src/ui-kit/customLink/CustomLink';

import { useTotalBasket } from '../../../api/Api/basket';

import styles from './Heading.module.sass';

// Props
type HeadingProps = {
  title: string | null;
  breadCrumbs?: {
    title: string;
    link?: string;
  }[];
  setShownMenu?: (arg: boolean) => void;
};

const Heading: React.FC<HeadingProps> = ({ title, breadCrumbs, setShownMenu }) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  const userInfo = useFullProfile();

  const basket = useTotalBasket({
    skip: userInfo.initialLoad || userInfo.data?.role === UserRole.SELLER,
  });

  const buttons = [];

  if (!userInfo?.data?.role) {
    const currentPathQuery = queryString.stringify({ from: router.asPath });

    buttons.push(
      <React.Fragment key={1}>
        <Media greaterThanOrEqual="lPhone">
          <div className={styles.buttons}>
            <CustomLink link={`/login?${currentPathQuery}`} className={styles.login}>
              {t('Buttons.login.default')}
            </CustomLink>
            <div className={styles.signupButton}>
              <Button theme="flexible" link="/signup">
                {t('Buttons.signUp.default')}
              </Button>
            </div>
          </div>
        </Media>
        <Media lessThan="lPhone">
          <div className={styles.loginPhoneButton}>
            <Button theme="flexible" link="/signup">
              {t('Buttons.signUp.default')}
            </Button>
          </div>
        </Media>

        <div className={styles.btn}>
          <Button theme="ternary superSmall" link="https://zionodes.zendesk.com/hc/en-us" external>
            <FaqIcon />
          </Button>
        </div>

        <div className={cx(styles.btn, { [styles.active]: Boolean(basket.data?.items?.length) })}>
          <Button theme="ternary superSmall" link="/basket">
            <PackageIcon />
          </Button>
        </div>
      </React.Fragment>,
    );
  } else if (userInfo.data.role === UserRole.USER) {
    buttons.push(
      <React.Fragment key={1}>
        <div className={cx(styles.btn, { [styles.active]: Boolean(basket.data?.items?.length) })}>
          <Button theme="ternary superSmall" link="/basket">
            <PackageIcon />
          </Button>
        </div>
        <div className={styles.btn}>
          <Button theme="ternary superSmall" link="https://zionodes.zendesk.com/hc/en-us" external>
            <FaqIcon />
          </Button>
        </div>
      </React.Fragment>,
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <Media lessThan="lTablet">
          <button
            className={cx(styles.buttonShowMenu, {
              [styles.buttonShowMenuDatkTheme]: !isLightTheme,
            })}
            onClick={() => setShownMenu && setShownMenu(true)}
            type="button"
          >
            <span />
          </button>
        </Media>

        <div className={styles.head}>{title && <h1>{title}</h1>}</div>
        <div className={styles.buttonsWrapper}>
          {buttons}
          {userInfo?.data && <UserMenu />}
          <LanguageChange className={styles.languages} />
        </div>
      </div>
      {(breadCrumbs?.length || 0) > 0 && (
        <ol itemScope itemType="https://schema.org/BreadcrumbList" className={styles.breadCrumbs}>
          {breadCrumbs?.map((el, index) => {
            if (el.link) {
              return (
                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                  key={`${el.link}${index}`}
                  className={styles.breadCrumbsItem}
                >
                  <Link prefetch={false} href={el.link}>
                    <a itemProp="item" href={el.link} className={styles.breadCrumbsLink}>
                      <span itemProp="name">{el.title}</span>
                    </a>
                  </Link>
                  <meta itemProp="position" content={String(index + 1)} />
                  <ArrowIcon />
                </li>
              );
            }

            return (
              <li
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
                key={`${el.title}${index}`}
                className={cx(styles.breadCrumbsItem, styles.breadCrumbsItemActive)}
              >
                <Link prefetch={false} href={router.asPath}>
                  <a
                    itemProp="item"
                    href={router.asPath}
                    className={cx(styles.breadCrumbsLink, styles.breadCrumbsLinkActive)}
                  >
                    <span itemProp="name">{el.title}</span>
                  </a>
                </Link>
                <meta itemProp="position" content={String(index + 1)} />
              </li>
            );
          })}
        </ol>
      )}
    </div>
  );
};

export default Heading;
