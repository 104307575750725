import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import LogOutIcon from '@icons/menu/Logout.svg';
import MarketplaceIcon from '@icons/menu/MarketplaceMenu.svg';
import MyFinance from '@icons/menu/MyFinanceActive.svg';
import ReviewsActive from '@icons/menu/ReviewsActive.svg';
import SettingsIcon from '@icons/menu/Settings.svg';
import StockIcon from '@icons/menu/StockActive.svg';
import PlaceholderUser from '@icons/placeholders/placeholder-user-small.svg';
import PackageIcon from '@icons/ui-kit/package.svg';
import { useFullProfile } from '@src/api/Api';
import { useCurrentTheme, THEME } from '@src/api/local/theme';
import { UserRole } from '@src/api/models/enums';
import { Media } from '@src/utils/media';
import Button from 'src/ui-kit/button/Button';
import { IMAGES_URL } from 'src/utils/constants';

import LogoutModal from '../LogoutModal/LogoutModal';

import styles from './UserMenu.module.sass';

const UserMenu: React.FC = () => {
  const userInfo = useFullProfile();

  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  const isSeller = userInfo?.data?.role === UserRole.SELLER;
  const isUser = userInfo?.data?.role === UserRole.USER;
  const isSellerWithCompany = Boolean(userInfo?.data?.company);

  const { t } = useTranslation('common');

  const [isOpened, setIsOpened] = useState(false);
  const [modalLogout, setModalLogout] = useState(false);

  const displayName = userInfo?.data
    ? `${userInfo.data?.first_name} ${userInfo.data?.last_name}`
    : '';

  const toggleOpened = () => {
    setIsOpened((oldState) => !oldState);
  };
  const toggleLogoutModal = () => {
    setModalLogout((oldState) => !oldState);
  };

  return (
    <>
      <div className={styles.menuWrapper}>
        <div className={styles.menu}>
          <Button
            onClick={toggleOpened}
            className={cx(styles.mainBtn, { [styles.mainBtnDarkTheme]: !isLightTheme })}
            theme="ternary superSmall"
          >
            {userInfo?.data?.image ? (
              <img
                className={styles.personImage}
                src={IMAGES_URL + userInfo?.data?.image}
                alt={`${userInfo?.data?.first_name}'s`}
              />
            ) : (
              <span className={styles.innerUser}>
                <PlaceholderUser />
              </span>
            )}

            <Media greaterThanOrEqual="lPhone">
              {displayName.length > 1 && <span className={styles.displayName}>{displayName}</span>}
            </Media>
          </Button>
        </div>

        <div className={cx(styles.menuLinks, { [styles.menuLinksActive]: isOpened })}>
          <div className={cx(styles.menuContent, { [styles.menuContentDarkTheme]: !isLightTheme })}>
            <Button
              className={cx(styles.btn, { [styles.btnDark]: !isLightTheme })}
              theme="ternary superSmall"
              link="/settings"
            >
              <span className={styles.inner}>
                <SettingsIcon />
              </span>
              <Media greaterThanOrEqual="lPhone">
                <span className={styles.btnTitle}>{t('Notation.settings')}</span>
              </Media>
            </Button>

            {(isSellerWithCompany || isUser) && (
              <Button
                className={cx(styles.btn, { [styles.btnDark]: !isLightTheme })}
                theme="ternary superSmall"
                link={isSeller ? '/sold-orders' : '/order-list'}
              >
                <span className={styles.inner}>
                  <PackageIcon />
                </span>
                <Media greaterThanOrEqual="lPhone">
                  <span className={styles.btnTitle}>{t('Notation.orders')}</span>
                </Media>
              </Button>
            )}

            <Button
              className={cx(styles.btn, { [styles.btnDark]: !isLightTheme })}
              theme="ternary superSmall"
              link="/profile"
            >
              <span className={styles.inner}>
                <PlaceholderUser />
              </span>
              <Media greaterThanOrEqual="lPhone">
                <span className={styles.btnTitle}>{t('Notation.profile')}</span>
              </Media>
            </Button>

            {isSellerWithCompany && (
              <>
                <Button
                  className={cx(styles.btn, { [styles.btnDark]: !isLightTheme })}
                  theme="ternary superSmall"
                  link="/add-item"
                >
                  <span className={styles.inner}>
                    <MarketplaceIcon />
                  </span>
                  <Media greaterThanOrEqual="lPhone">
                    <span className={styles.btnTitle}>{t('Notation.addItem')}</span>
                  </Media>
                </Button>

                <Button
                  className={cx(styles.btn, { [styles.btnDark]: !isLightTheme })}
                  theme="ternary superSmall"
                  link="/stock"
                >
                  <span className={styles.inner}>
                    <StockIcon />
                  </span>
                  <Media greaterThanOrEqual="lPhone">
                    <span className={styles.btnTitle}>{t('Notation.stock')}</span>
                  </Media>
                </Button>

                <Button
                  className={cx(styles.btn, { [styles.btnDark]: !isLightTheme })}
                  theme="ternary superSmall"
                  link="/my-finance"
                >
                  <span className={styles.inner}>
                    <MyFinance />
                  </span>
                  <Media greaterThanOrEqual="lPhone">
                    <span className={styles.btnTitle}>{t('Notation.myFinance')}</span>
                  </Media>
                </Button>

                <Button
                  className={cx(styles.btn, { [styles.btnDark]: !isLightTheme })}
                  theme="ternary superSmall"
                  link="/reviews"
                >
                  <span className={styles.inner}>
                    <ReviewsActive />
                  </span>
                  <Media greaterThanOrEqual="lPhone">
                    <span className={styles.btnTitle}>{t('Notation.reviews')}</span>
                  </Media>
                </Button>
              </>
            )}

            <div className={styles.hr} />

            <Button
              className={cx(styles.btn, { [styles.btnDark]: !isLightTheme })}
              theme="ternary superSmall"
              onClick={toggleLogoutModal}
            >
              <span className={styles.inner}>
                <LogOutIcon />
              </span>
              <Media greaterThanOrEqual="lPhone">
                <span className={styles.btnTitle}>{t('Notation.logout')}</span>
              </Media>
            </Button>
          </div>
        </div>
      </div>

      <LogoutModal isOpen={modalLogout} onRequestClose={() => setModalLogout(false)} />
    </>
  );
};

export default UserMenu;
