import React, { ReactNode } from 'react';

import Heading from 'src/components/layout/Heading/Heading';

import styles from './content.module.sass';

// Props
type ContentProps = {
  isHeading?: boolean;
  children: ReactNode;
  title: string | null;
  breadCrumbs?: {
    title: string;
    link?: string;
  }[];
  setShownMenu?: (arg: boolean) => void;
};

const Content: React.FC<ContentProps> = ({
  isHeading = true,
  children,
  title,
  breadCrumbs,
  setShownMenu,
}) => (
  <div className={`${styles.content} content`}>
    {isHeading && <Heading title={title} breadCrumbs={breadCrumbs} setShownMenu={setShownMenu} />}
    {children}
  </div>
);
export default Content;
