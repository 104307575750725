import { useTranslation } from 'next-i18next';
import React from 'react';
import ReactModal from 'react-modal';

import { logoutUser } from '@src/api/Api';
import { useLoading } from '@src/hooks/useLoading';
import { deauthentify } from '@src/utils/auth/getIsAuthenticated';
import StyledCard from '@ui/StyledCard';
import Button from '@ui/button/Button';

import styles from './logout-modal.module.sass';

type LogoutModalProps = ReactModal.Props & {
  onRequestClose: () => void;
};

const LogoutModal: React.FC<LogoutModalProps> = ({ onRequestClose, ...props }) => {
  const { t } = useTranslation('common');

  const [, setIsLoading] = useLoading();

  const logout = async () => {
    setIsLoading(true);
    await logoutUser();
    setIsLoading(false);
    deauthentify();

    window.location.href = '/marketplace';
  };

  return (
    <ReactModal
      {...props}
      appElement={
        typeof window !== 'undefined' ? (document.querySelector('body') as HTMLElement) : undefined
      }
      overlayClassName="Modal__Overlay"
      className="Modal__Content"
      onRequestClose={onRequestClose}
    >
      <StyledCard theme="reverse">
        <div className={styles.modalContent}>
          <h2>{t('Notifications.Logout.sure')}</h2>
          <div className={styles.modalButtons}>
            <div className={styles.modalButton}>
              <Button theme="middle" onClick={onRequestClose}>
                {t('Notifications.Logout.stay')}
              </Button>
            </div>
            <div className={styles.modalButton}>
              <Button theme="middle ternary" onClick={logout}>
                {t('Notifications.Logout.yes')}
              </Button>
            </div>
          </div>
        </div>
      </StyledCard>
    </ReactModal>
  );
};

export default LogoutModal;
