import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { FC, useRef, useState } from 'react';

import ZionodesLogoDarkTheme from '@icons/ZionodesLogoDarkPronounced.svg';
import Logout from '@icons/menu/Logout.svg';
import LogoutActive from '@icons/menu/LogoutActive.svg';
import LogoutActiveDarkTheme from '@icons/menu/LogoutActiveDarkTheme.svg';
import ThemeSwitch from '@icons/menu/themeSwitch.svg';
import ThemeSwitchLight from '@icons/menu/themeSwitchLight.svg';
import Zionodes from '@icons/menu/zionodesPronounced.svg';
import Cross from '@icons/ui-kit/Cross.svg';
import { useDepartments, useFullProfile } from '@src/api/Api';
import { useCurrentTheme, THEME } from '@src/api/local/theme';
import { Media } from '@src/utils/media';
import { changeTheme } from '@src/utils/themeChanger';
import Button from 'src/ui-kit/button/Button';

import LogoutModal from '../LogoutModal/LogoutModal';

import styles from './NavBar.module.sass';
import NavButton, { NavButtonProps } from './NavButton';
import { navButtonAll } from './navButtons';

// Props
type NavBarProps = {
  shownMenu?: boolean;
  setShownMenu?: (arg: boolean) => void;
};

const NavBar: FC<NavBarProps> = ({ shownMenu = false, setShownMenu }: NavBarProps) => {
  const { t } = useTranslation('common');

  const themeChangerRef = useRef<HTMLDivElement>(null);
  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  const departmentsData = useDepartments();
  const departments = (departmentsData && departmentsData.docs) || [];

  const [modalLogout, setModalLogout] = useState(false);

  const userInfo = useFullProfile();

  const departmentButtons = departments
    ? departments.map((department) => ({
        label: department.title,
        path: `/marketplace/${department.shortcut}`,
      }))
    : [];

  const navButtons: NavButtonProps[] = [...navButtonAll(isLightTheme), ...departmentButtons];

  const handleChangeTheme = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = (
      themeChangerRef.current?.querySelector('svg') as SVGElement
    ).getBoundingClientRect();
    const x = e.clientX - rect?.left; //x position within the element.

    // Change theme only if user clicked on children SVG element
    if (x - rect.width < 0) {
      const newTheme = x - rect.width / 2 > 0 ? THEME.dark : THEME.light;

      changeTheme(newTheme);
    }
  };

  return (
    <>
      <Media greaterThanOrEqual="tablet">
        <div
          className={cx(styles.overlay, { [styles.active]: shownMenu })}
          onClick={() => setShownMenu && setShownMenu(false)}
        />
      </Media>

      <div className={cx(styles.navBar, { [styles.active]: shownMenu })}>
        <Media lessThan="tablet">
          <div className={styles.closeButton}>
            <Button
              theme="flexible ternary superSmall"
              onClick={() => setShownMenu && setShownMenu(false)}
            >
              <Cross />
            </Button>
          </div>
        </Media>

        <div className={styles.row}>
          <Link prefetch={false} href="/marketplace">
            <a className={styles.logo}>{isLightTheme ? <Zionodes /> : <ZionodesLogoDarkTheme />}</a>
          </Link>
          <nav className={styles.nav}>
            {navButtons.map((button) => {
              return (
                (userInfo?.data?.role || !button.isHidden) && (
                  <NavButton
                    key={button.path}
                    path={button.path}
                    label={button.label}
                    href={button.href}
                    icon={button.icon}
                    iconActive={button.iconActive}
                  />
                )
              );
            })}

            {userInfo?.data?.role && (
              <NavButton
                icon={<Logout />}
                iconActive={isLightTheme ? <LogoutActive /> : <LogoutActiveDarkTheme />}
                label={t('Notation.logout')}
                onClick={() => setModalLogout(true)}
                isLast
              />
            )}
          </nav>
          <div
            onClick={handleChangeTheme}
            className={cx(styles.themeChanger, {
              [styles.themeChangerLight]: colorTheme.data?.theme === THEME.light,
            })}
            ref={themeChangerRef}
          >
            <p className={styles.themeChangerText}>{t('Notation.changeTheme')}</p>
            {isLightTheme ? <ThemeSwitchLight /> : <ThemeSwitch />}
          </div>
        </div>
      </div>

      <LogoutModal isOpen={modalLogout} onRequestClose={() => setModalLogout(false)} />
    </>
  );
};

NavBar.defaultProps = {
  shownMenu: true,
  setShownMenu: undefined,
};

export default NavBar;
