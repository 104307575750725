import React, { useState, useEffect } from 'react';

import { useTopLoader } from '@src/context/TopLoaderContext';

export const useLoading = (
  initialState = false,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const loadBar = useTopLoader();
  const [isLoading, setIsLoading] = useState(initialState);

  useEffect(() => {
    if (isLoading) {
      // This is react-top-loading-bar typescript bug, in fact it has 2 optional arguments, it is described in documentation
      // https://github.com/klendi/react-top-loading-bar
      // @ts-ignore
      loadBar?.loadBarRef.current?.continuousStart();
    } else {
      loadBar?.loadBarRef.current?.complete();
    }
  }, [isLoading, loadBar?.loadBarRef]);

  return [isLoading, setIsLoading];
};
