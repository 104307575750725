import cx from 'classnames';
import Link from 'next/link';
import React, { ReactNode } from 'react';

import s from './custom-link.module.sass';

// Props
type CustomLinkProps = {
  className?: string;
  link: string;
  external?: boolean;
  children: ReactNode;
};

const CustomLink: React.FC<CustomLinkProps> = ({ className, children, link, external = false }) => {
  if (external) {
    return (
      <a href={link} target="_blank" rel="noreferrer noopener" className={cx(s.link, className)}>
        {children}
      </a>
    );
  }

  return (
    <Link prefetch={false} href={link}>
      <a href={link} className={cx(s.link, className)}>
        {children}
      </a>
    </Link>
  );
};

export default CustomLink;
