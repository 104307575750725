import React, { ReactNode, useState } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';

import Meta from 'src/ui-kit/Meta/Meta';

import Content from '../content/Content';
import NavBar from '../navBar/NavBar';
import '../../../components/authentication/Login/provide-data-message.module.sass';

// Props
type MainLayoutProps = {
  isHeading?: boolean;
  noIndex?: boolean;
  children: ReactNode;
  title?: string;
  contentTitle?: string | null;
  description?: string;
  canonicalUrl?: string;
  breadCrumbs?: {
    title: string;
    link?: string;
  }[];
};

export default function MainLayout({
  children,
  title,
  isHeading,
  breadCrumbs,
  noIndex,
  description,
  contentTitle,
  canonicalUrl,
}: MainLayoutProps) {
  const [shownMenu, setShownMenu] = useState(false);

  return (
    <>
      <NavBar shownMenu={shownMenu} setShownMenu={setShownMenu} />
      <Meta canonicalUrl={canonicalUrl} description={description} noIndex={noIndex} title={title} />

      <Content
        isHeading={isHeading}
        title={typeof contentTitle !== 'undefined' ? contentTitle : title || ''}
        breadCrumbs={breadCrumbs}
        setShownMenu={setShownMenu}
      >
        <ToastContainer
          autoClose={5000}
          hideProgressBar
          className="notification"
          draggable
          pauseOnHover
          closeOnClick={false}
          pauseOnFocusLoss
          limit={2}
          transition={Zoom}
        />
        {children}
      </Content>
    </>
  );
}

MainLayout.defaultProps = {
  isHeading: undefined,
  title: '',
  breadCrumbs: [],
};
